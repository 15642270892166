<template>
<div id="Awards">

<div>
  <div class="pa-5 secondary elevation-5 bg-accent-card">
    <p class="mt-5 display-1">Awards
      <v-btn color="accent" style="margin-top: -15px" @click="$router.push('/addaward')" fab dark absolute right >
        <v-icon> add </v-icon>
      </v-btn>
    </p>
  </div>
</div>  

<datatablevue :tableData="tableData"></datatablevue>

</div>
</template>

<script>
const datatablevue = () => import(/* webpackChunkName: "datatablevue" */ '../../components/shared/dataTable');



export default {

    name: 'Awards',

    components: {
        datatablevue
    },

    data(){return{

        // table headers
        headers: [ 
            { text: 'Title', value: 'title' }, 
            { text: 'Description', value: 'description' }, 
        ],

    }},

    computed: {

        GetAwards(){
            return this.$store.getters.GetAllAwards;
        },

        tableData(){
            return {'headers': this.headers, 'Data': this.GetAwards}
        },

    },

}
</script>
